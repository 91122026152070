// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-pinggod-gatsby-theme-wink-src-templates-page-tsx": () => import("/opt/build/repo/node_modules/@pinggod/gatsby-theme-wink/src/templates/page.tsx" /* webpackChunkName: "component---node-modules-pinggod-gatsby-theme-wink-src-templates-page-tsx" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-pinggod-gatsby-theme-wink-src-templates-post-tsx": () => import("/opt/build/repo/node_modules/@pinggod/gatsby-theme-wink/src/templates/post.tsx" /* webpackChunkName: "component---node-modules-pinggod-gatsby-theme-wink-src-templates-post-tsx" */),
  "component---node-modules-pinggod-gatsby-theme-wink-src-pages-404-tsx": () => import("/opt/build/repo/node_modules/@pinggod/gatsby-theme-wink/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-pinggod-gatsby-theme-wink-src-pages-404-tsx" */),
  "component---node-modules-pinggod-gatsby-theme-wink-src-pages-about-tsx": () => import("/opt/build/repo/node_modules/@pinggod/gatsby-theme-wink/src/pages/about.tsx" /* webpackChunkName: "component---node-modules-pinggod-gatsby-theme-wink-src-pages-about-tsx" */)
}

